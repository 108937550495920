<template>
  <v-card flat class="mt-0">
    <v-form>
      <div class="px-0">
        <v-card-text class="pt-5">
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <!-- current password -->
              <v-text-field v-model="currentPassword" :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Current Password" outlined dense
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"></v-text-field>

              <!-- new password -->
              <v-text-field v-model="newPassword" :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" label="New Password"
                outlined dense hint="Make sure it's at least 8 characters." persistent-hint
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"></v-text-field>

              <!-- confirm password -->
              <v-text-field v-model="cPassword" :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                label="Confirm New Password" outlined dense class="mt-3"
                @click:append="isCPasswordVisible = !isCPasswordVisible"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </div>

      <!-- divider -->
      <v-divider></v-divider>

      <div class="">
        <v-card-text>
          <v-btn color="primary" class="me-3 mt-3" @click="saveChangePassword()">
            Simpan
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { axiosPostAuth, axiosGetAuth } from '@/utils/axios'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref('')
    const newPassword = ref('')
    const cPassword = ref('')

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
  computed: {
    formTitle() {
      return this.form.editedIndex === -1 ? 'Tambah Item' : 'Lihat Data'
    },
    user() {
      return this.$store.getters.user
    },
  },
  methods: {
    saveChangePassword() {
      const fmData = new FormData()
      fmData.append('currentPassword', this.currentPassword)
      fmData.append('newPassword', this.newPassword)
      fmData.append('cPassword', this.cPassword)
      axiosPostAuth('api/User/changePassword', fmData)
        .then(response => {
          if (response.code == 200) {
            alert("Password berhasil dirubah. Silahkan login ulang ke sistem")
            this.$store.dispatch('logout')
          } else {
            alert(response.message)
          }
        })
        .catch(error => {
          alert(error)
        })
    }
  },
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}

.security-character {
  position: absolute;
  bottom: -0.5rem;
}
</style>
